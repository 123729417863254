<template>
  <div class="login clearfix">
    <img
      src="../../../assets/images/account/gb_left@2x.png"
      class="bg_position bg_left"
      alt=""
    />
    <img
      src="../../../assets/images/account/gb_right@2x.png"
      class="bg_position bg_right"
      alt=""
    />
    <div class="login__left">
      <img src="@/assets/images/account/login_img.png" />
    </div>
    <div class="login__right">
      <div class="login__header">
        <div>欢迎使用</div>
        <div class="login__header__title">Zarta股权激励系统</div>
      </div>

      <el-form
        class="login__body"
        ref="loginForm"
        :model="loginForm"
        :rules="loginRules"
        auto-complete="on"
        hide-required-asterisk
      >
        <el-form-item prop="username">
          <el-input
            type="text"
            auto-complete="on"
            autofocus
            v-model="loginForm.username"
            placeholder="请输入账号"
          >
            <div slot="prefix" class="prefix flex-h flex-vc">
              <img
                src="@/assets/images/account/account.png"
                alt=""
                class="img_icon"
              />
            </div>
          </el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input
            :type="passwordType"
            auto-complete="on"
            v-model="loginForm.password"
            @keyup.enter.native="handleLogin"
            placeholder="请输入密码"
          >
            <div slot="prefix" class="prefix flex-h flex-vc">
              <img
                src="@/assets/images/account/secret.png"
                alt=""
                class="img_icon"
              />
            </div>
          </el-input>
        </el-form-item>

        <el-form-item prop="code">
          <div class="erCode__box flex-h flex-vc">
            <el-input
              v-model="loginForm.code"
              @keyup.enter.native="handleLogin"
              placeholder="请输入验证码"
              class="erCode"
            >
              <div slot="prefix" class="prefix flex-h flex-vc">
                <img
                  src="@/assets/images/account/code.png"
                  alt=""
                  class="img_icon"
                />
              </div>
            </el-input>
            <div class="captcha__box flex-h flex-vc" @click="changeCaptcha">
              <img :src="erCode" alt="" class="erCode_img" />
              <img
                class="fresh_icon"
                src="@/assets/images/account/fresh.png"
                alt=""
              />
            </div>
          </div>
        </el-form-item>
        <el-form-item style="padding-top: 15px; margin-bottom: 9px">
          <el-button
            type="primary"
            size="medium"
            :loading="loginLoading"
            @click="handleLogin"
            >登录</el-button
          >
        </el-form-item>
        <el-form-item>
          <div class="flex-h flex-vc flex-jsb">
            <el-checkbox v-model="loginForm.rememberPwd">
              记住登录账号
            </el-checkbox>
            <el-button type="text" @click="$router.push({ path: '/applyUse' })"
              >新用户申请</el-button
            >
          </div>
        </el-form-item>
      </el-form>
      <div class="copyright">清科证券有限公司 版权所有</div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import service from "@/service/service";
export default {
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        code: "",
        rememberPwd: true,
      },
      loginRules: {
        username: [
          {
            required: true,
            message: "账号不能为空",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            message: "密码长度不能少于六位",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
          {
            min: 4,
            message: "验证码长度不能少于四位",
            trigger: "blur",
          },
        ],
      },
      passwordType: "password",
      loginLoading: false,
      erCode: "",
    };
  },
  created() {
    let username = localStorage.getItem("username") || "";
    this.loginForm.username = username;
    this.getCaptcha();
  },
  methods: {
    changeCaptcha() {
      this.getCaptcha();
    },
    getCaptcha() {
      api.account
        .publicCaptcha()
        .then((res) => {
          return (
            "data:image/png;base64," +
            btoa(
              new Uint8Array(res.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            )
          );
        })
        .then((res) => {
          this.erCode = res;
        });
    },

    showPwd() {
      this.passwordType = this.passwordType == "password" ? "text" : "password";
    },
    handleLogin() {
      this.loginLoading = true;
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          const account = {
            username: this.loginForm.username,
            password: this.loginForm.password,
            code: this.loginForm.code,
            rememberPwd: this.loginForm.rememberPwd,
          };
          this.$store
            .dispatch("account/Login", account)
            .then((res) => {
              const { user_token, user_id } = res;
              service.setHeader({
                user_token,
                user_id,
              });
              this.$router.replace("/").catch(() => {});
              this.loginLoading = false;
            })
            .catch(() => {
              // this.$message.error("登录失败,请填写正确的信息！");
              this.loginLoading = false;
            });
        } else {
          this.$message.error("登录失败,请填写正确的信息！");
          this.loginLoading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 800px;
  height: 520px;
  // margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  // box-sizing: border-box;
  .bg_position {
    position: absolute;
    &.bg_left {
      width: 244px;
      top: 138px;
      left: -244px;
    }
    &.bg_right {
      top: 239px;
      width: 175px;
      right: -175px;
    }
  }
  .img_icon {
    width: 24px;
    margin: 0 8px;
    display: inline-block;
  }
  &__left {
    width: 50%;
    float: left;
    overflow: hidden;
    background-color: #f7f8f9;
    border-radius: 16px 0 0 16px;
    img {
      width: 100%;
      display: block;
    }
  }
  &__right {
    width: 50%;
    float: right;
    padding: 40px;
    height: 100%;
    padding-bottom: 16px;
    box-sizing: border-box;
    border-radius: 0px 16px 16px 0;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    .login__header {
      font-size: 14px;
      color: #898a8c;
      line-height: 22px;
      &__title {
        font-size: 28px;
        color: #333333;
        line-height: 42px;
        font-weight: 600;
        // margin-top: 8px;
      }
    }

    .login__body {
      margin-top: 32px;
      /deep/ .el-form-item {
        margin-bottom: 24px;
        .el-input {
          .el-input__inner {
            height: 40px;
            line-height: 40px;
            padding-left: 44px;
          }
        }
        .el-button.el-button--primary.el-button--medium {
          width: 100%;
        }
      }
      .prefix {
        height: 100%;
      }
      .erCode__box {
        .erCode {
          width: 156px;
        }
        .captcha__box {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 30px;
          margin-top: 3px;
          color: $theme;
          .erCode_img {
            width: 108px;
            border: none;
            margin: 0 16px;
          }
          .fresh_icon {
            width: 24px;
          }
        }
      }
    }
    .copyright {
      margin-top: 50px;
      text-align: center;
      font-size: 12px;
      color: #898a8c;
      line-height: 22px;
      font-weight: 400;
    }
  }
}
</style>
